import './HeaderComponent.css';
import { NavLink } from 'react-router-dom';

function Header() {
    const navigationLinks = [
        {
            title: 'Features',
            url: 'https://www.cobraswap.io/#Features'
        },

        {
            title: 'Integration',
            url: 'https://www.cobraswap.io/#integration'
        },
        {
            title: 'Roadmap',
            url: 'https://www.cobraswap.io/#RoadMap'
        },
        {
            title: 'Staking',
            url: '/staking'
        },

    ];
    const dappMenuItems = [
        /*
        {
            title: 'Roadmap',
            url: 'https://pancakeswap.finance/swap?outputCurrency=0xdd63e7fda4aad4132d7bf38de04ecc61c6c4b368',
            important: 'important'
        },
         */

        {
            title: 'BRIDGE APP',
            url: '/crosschainswap',
            important: 'important'
        },
    ]
    const closeBurgerMenu = () => {
        document.getElementById('menu-toggle').checked = false;
    };
    return (
        <div className="HeaderWrapper">
            <header className="Header">
                <section className="top-nav">
                    <a href=""><img className='imgHeader' src="https://assets-global.website-files.com/6564793b98f6814022ab6bdf/656c7f97e729cf88b85abe6e_Cobra-Logo_new%2B-p-500.png" alt=""/></a>

                    <input id="menu-toggle" type="checkbox" />
                    <label className="menu-button-container" htmlFor="menu-toggle">
                        <div className="menu-button"></div>
                    </label>


                    <ul className="menu">
                        {navigationLinks.map(el => {
                            return <li className={el.important ? "NavigationItem dappElement" : "NavigationItem"} key={el.title}>
                                <NavLink onClick={closeBurgerMenu} activeclassname="active" className={`NavigationLink ${el.important}`}
                                         to={el.url}>{el.title}</NavLink>
                            </li>;
                        })}
                        <li className="DappNavifationItem">
                            <ul className="dappmenu">
                                {dappMenuItems.map(el => {
                                    return <li className={el.important ? "dappElement" : "NavigationItem"} key={el.title}>
                                        <NavLink onClick={closeBurgerMenu} activeclassname="active" className={`NavigationLink ${el.important}`}
                                                 to={el.url}>{el.title}</NavLink>
                                    </li>;
                                })}
                            </ul>
                        </li>
                    </ul>

                </section>
            </header>
        </div>
    );
}

export default Header;
