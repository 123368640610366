import {useEffect, useState} from 'react';
import {Bridge} from '@socket.tech/plugin';
import {ethers} from 'ethers';
import frog from '../../../assets/logo2.png';
import {isMetaMaskConnected, removeTag, SOCKET_API} from '../../utils';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';

export const CrossChainSwapComponent = (args) => {
    const [provider, setProvider] = useState();
    const [userAddress, setUserAddress] = useState();
    const [loggedIn, setLoggedIn] = useState(true);

    const fetchWalletData = async () => {
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const userAddress = await signer.getAddress();

            if (provider) {
                setProvider(provider);
                setUserAddress(userAddress);
            }
        } catch (e) {
            console.log('Error fetchWalletData:', e);
            toastr.error('Oops! It seems there\'s an error fetching data from your wallet.', '', {displayDuration: 6000});
        }
    };

    const connectWallet = async () => {
        try {
            if (window.ethereum) {
                const provider = new ethers.BrowserProvider(window.ethereum);
                await provider.send('eth_requestAccounts', [{eth_accounts: {}}]);
                const signer = await provider.getSigner();
                const userAddress = await signer.getAddress();
                setProvider(provider);
                setUserAddress(userAddress);
                setLoggedIn(true);
            } else {
                toastr.error('Web3 wallet not detected');
            }
        } catch (e) {
            console.log('Error connectWallet:', e);
            toastr.error('Oops! It seems there\'s an error connecting to your wallet.', '', {displayDuration: 6000});
        }
    };

    useEffect(() => {
        removeTag();
        if (window.ethereum) {
            isMetaMaskConnected().then(isConnected => {
                setLoggedIn(isConnected);
                if (isConnected) {
                    connectWallet();
                }
            });
            window.ethereum.on('chainChanged', (args) => {
                console.log('chainChanged event:', args);
                fetchWalletData();
            });
            window.ethereum.on('accountsChanged', (args) => {
                console.log('accountsChanged args', args);
                if (args.length === 0) {
                    window.ethereum.removeAllListeners('accountsChanged');
                    setProvider(null);
                    setUserAddress(null);
                    setLoggedIn(false);
                } else {
                    fetchWalletData();
                }
            });
        }
    }, []);

    return (
        <div style={{textAlign: 'center', marginTop: '30px'}}>
            <div style={{marginBottom: '30px'}}>
                {!loggedIn && (
                    <button
                        onClick={connectWallet}
                        className="ConnectedButton"
                    >
                        Connect Wallet
                    </button>
                )}
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Bridge
                    {...args}
                    provider={provider}
                    API_KEY={SOCKET_API}
                />
            </div>
            <img src={frog} alt=""/>
            {/* <img style={{width: '100%', position: 'absolute', left: '0', bottom: '0', zIndex: '-1'}} src={frog}
                 alt=""/> */}


        </div>
    );
};

const Customize = {
    secondary: 'rgb(68,69,79)',
    primary: 'rgb(31,34,44)',
    accent: 'rgb(131,249,151)',
    onAccent: 'rgb(0,0,0)',
    interactive: 'rgb(0,0,0)',
    onInteractive: 'rgb(240,240,240)',
    text: 'rgb(255,255,255)',
    secondaryText: 'rgb(200,200,200)'
};

export const Default = CrossChainSwapComponent.bind({});
Default.args = {
    API_KEY: SOCKET_API,
    customize: Customize,
    enableSameChainSwaps: true,
    enableRefuel: true,
};
